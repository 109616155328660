{
  "lang_tags": "Etiquetas",
  "lang_days": "{0,plural,zero {hoxe},one {onte},other {fai # días}}",
  "lang_unmark": "Marcar como non lido",
  "lang_share_pocket_label": "Gardar en Pocket",
  "lang_no_entries": "Non se atoparon rexistros",
  "lang_more": "Máis",
  "lang_filter": "Filtro",
  "lang_online_count": "Elementos dispoñibles no servidor",
  "lang_offline_count": "Elementos dispoñibles localmente",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "fai {0,plural,one {1 hora},other {# horas}}",
  "lang_minutes": "fai {0,plural,one {1 minuto},other {# minutos}}",
  "lang_seconds": "fai {0,plural,one {1 segundo},other {# segundos}}",
  "lang_share_wallabag_label": "Gardar en Wallabag",
  "lang_share_wordpress_label": "Compartir en WordPress",
  "lang_share_mail_label": "Compartir por email",
  "lang_share_copy_label": "Copiar ligazón o portapapéis",
  "lang_search_label": "Buscar termo",
  "lang_searchbutton": "Procurar",
  "lang_searchremove": "Agochar búsqueda",
  "lang_refreshbutton": "Actualizar as fontes",
  "lang_settingsbutton": "Configuración",
  "lang_logoutbutton": "Fechar a sesión",
  "lang_loginbutton": "Iniciar sesión",
  "lang_sources": "Fontes",
  "lang_sources_loading": "Cargando as fontes…",
  "lang_no_sources": "Non hai fontes configuradas.",
  "lang_source_add": "Engadir fonte",
  "lang_source_opml": "ou importa dende un ficheiro OPML",
  "lang_source_export": "Exportar fontes",
  "lang_source_edit": "Editar",
  "lang_source_filter": "Filtro",
  "lang_source_menu": "Máis accións",
  "lang_source_browse": "Navegar",
  "lang_source_delete": "Borrar",
  "lang_source_warn": "Realmente queres borrar esta fonte?",
  "lang_source_deleting": "Borrando a fonte…",
  "lang_source_warn_cancel_dirty": "Queres descargar os cambios non gardados?",
  "lang_source_new": "Nova fonte sen gardar",
  "lang_source_title": "Título",
  "lang_source_autotitle_hint": "Deixar en branco pra obter título",
  "lang_source_type": "Tipo",
  "lang_source_save": "Gardar",
  "lang_source_cancel": "Cancelar",
  "lang_source_saving": "Gardando cambios da fonte…",
  "lang_source_last_post": "Última entrada vista",
  "lang_error_session_expired": "A sesión expirou",
  "lang_error_add_source": "Non se puido engadira a fonte:",
  "lang_error_edit_source": "Non se puideron gardar os cambios as fontes:",
  "lang_error_delete_source": "Erro borrando a fonte:",
  "lang_error_load_tags": "Non se puideron cargar as etiquetas:",
  "lang_error_unknown_tag": "Etiqueta descoñecida:",
  "lang_error_unknown_source": "Id de fonte descoñecida:",
  "lang_error_mark_items": "Non se puideron marcar todos os elementos visibéis:",
  "lang_error_mark_item": "Non se puido marcar/desmarcar elemento:",
  "lang_error_star_item": "Non se puido marcar/desmarcar a estrela no elemento:",
  "lang_error_logout": "Non se puido fechar a sesión:",
  "lang_error_loading": "Error cargando:",
  "lang_error_loading_stats": "Non se puideron cargar as estatísticas de navegación:",
  "lang_error_saving_color": "Non se puido cargar a nova cor:",
  "lang_error_refreshing_source": "Non se puideron refrescar as fontes:",
  "lang_error_sync": "Non se puideron sincronizar os últimos cambios do servidor:",
  "lang_error_offline_storage": "Error do almacenamento fora de liña: {0}. Recargar pode axudar. Desactivando uso fora de liña polo momento.",
  "lang_share_twitter_label": "Compartir en Twitter",
  "lang_error_offline_storage_not_available": "Almacenamento fora de liña non dispoñíbel. Asegurase de que o seu navegador {0} soporta IndexedDB API{1} e, se está utilizando un navegador baseado en Google Chrome, que non esté usando selfoss en modo incógnito.",
  "lang_error_invalid_subsection": "Subsección inválida:",
  "lang_error_configuration": "Non se puido cargar a configuración.",
  "lang_error_share_native_abort": "Non se puido compartir o elemento, non tes onde compartilo ou canceláchelo.",
  "lang_error_share_native": "Non se puido compartir o elemento.",
  "lang_info_url_copied": "Copiouse a URL o portapapéis.",
  "lang_close_entry": "Fechar",
  "lang_article_actions": "Accións",
  "lang_article_reading_time": "{0} min lectura",
  "lang_unread": "Non lidos",
  "lang_offline_navigation_unavailable": "Cambiar etiquetas e fontes non está dispoñible no modo fora de liña.",
  "lang_source_go_to_settings": "Abrir a configuración",
  "lang_login_in_progress": "Intentando iniciar sesión…",
  "lang_markread": "Marcar como lido",
  "lang_newest": "Novos",
  "lang_alltags": "Todas as etiquetas",
  "lang_source_saved": "Gardado",
  "lang_source_refresh": "Actualizar esta fonte",
  "lang_tag_change_color_button_title": "Cambiar a cor",
  "lang_login": "Iniciar sesión",
  "lang_login_password": "Contrasinal",
  "lang_sources_refreshed": "Actualizaronse as fontes",
  "lang_star": "Marcar con estrela",
  "lang_starred": "Con estrela",
  "lang_unstar": "Quitar a estrela",
  "lang_next": "Seguinte",
  "lang_share_native_label": "Compartir",
  "lang_share_diaspora_label": "Compartir en Diaspora",
  "lang_share_facebook_label": "Compartir en Facebook",
  "lang_source_comma": "Separado por comas",
  "lang_mark": "Marcar como lido",
  "lang_open_window": "Abrir",
  "lang_load_img": "Cargar imaxes",
  "lang_login_invalid_credentials": "Usuario/contrasinal incorrectos",
  "lang_source_tags": "Etiquetas",
  "lang_source_select": "Por favor, escolle unha fonte",
  "lang_source_pwd_placeholder": "Non cambiou",
  "lang_source_params_loading": "Estanse cargando os parámetros…",
  "lang_sources_leaving_unsaved_prompt": "Queres deixar a configuración con cambios de fonte non gardados?",
  "lang_login_username": "Usuario",
  "lang_entries_loading": "Estanse cargando as entradas…",
  "lang_login_offline": "Almacenamento fora de liña",
  "lang_error": "Ocurriu un erro",
  "lang_error_check_system_logs": "Ocurriu un erro, por favor comproba os rexistros do sistema.",
  "lang_error_unwritable_logs": "Non se puideron escribir os rexistros.",
  "lang_no_title": "Sen título",
  "lang_experimental": "Experimental",
  "lang_error_check_log_file": "Produciuse un erro, por favor comproba o ficheiro de rexistro \"{0}\".",
  "lang_streamerror": "Non hai elementos cargados, recargar?",
  "lang_reload_list": "Recargar lista",
  "lang_app_update": "Selfoss actualizouse, recargue",
  "lang_app_reload": "Recargar",
  "lang_error_unauthorized": "{link_begin}inicia sesión{link_end} para continuar.",
  "lang_login_error_generic": "Non se puido iniciar sesión: {errorMessage}"
}
